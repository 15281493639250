.nav-primary {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        color: $color-white;
        font-weight: $font-weight-medium;
        font-size: $font-size;
        text-decoration: none;
        padding: $font-size-smaller $font-size-big;
        @media screen and (max-width: 1320px) {
          font-size: 0.95rem;
          padding-left: $font-size-small;
          padding-right: $font-size-small;
        }
      }
    }
    #menu-item-38 {
      a {
        @include button;
        padding: 0.5rem 1.25em;
        background-color: $color-white;
        border-color: $color-white;
        color: $color-grey-2;
        margin-left: 0.875rem;
        &:hover {
          background: darken($color-primary, 5%);
          border-color: darken($color-primary, 5%);
          color: $color-white;
          box-shadow: $box-shadow-small;
          transform: translateY(-2px);
          transition: $trans;
        }
      }
    }
    li.menu-item-has-children {
      position: relative;
      padding-right: 0.5rem;
      .sub-menu {
        background-color: $color-secondary;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: none;
        position: absolute;
        box-shadow: $box-shadow;
        min-width: 220px;
        width: max-content;
        top: 2.5rem;
        z-index: 2;
        border-radius: 5px;
        li {
          a {
            color: $color-background;
            font-size: 0.9rem;
            padding: 0.5rem 1.25rem;
            font-weight: $font-weight-medium;
            display: block;
            text-decoration: none;
            font-size: $font-size;
            &:hover {
              background-color: $color-white;
              color: $color-grey-2;
            }
          }
        }
      }
    }
  }
}

.nav-primary {
  .menu-item-has-children {
    margin-right: $font-size;
    @media screen and (max-width: 1320px) {
      margin-right: 0.4rem;
    }
    @media screen and (max-width: 1154px) {
      margin-right: 0.5rem;
    }
    & > a {
      &::before {
        content: "";
        position: absolute;
        display: block;
        background: url("../assets/ico/ico-chevron-down.svg") no-repeat center
          center;
        filter: brightness(0) invert(1);
        background-size: auto;
        width: 10px;
        height: 7px;
        background-size: cover;
        opacity: 0.6;
        right: 6px;
        top: 9px;
      }
    }
  }
}

#offcanvasMenu {
  // width: 100vw;
  // border: none;
}

.offcanvas {
}

.offcanvas {
  &-body {
    background-color: $color-primary-dark;
    button {
      display: flex;
      margin-left: auto;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;
      //padding-right: 2.65rem;
      //padding-top: 1rem;
      text-align: right;
      i {
        color: $color-white;
        transition: $trans;

        &:hover {
          color: $color-primary-dark;
          transition: $trans;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    //padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: $space-1;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    & > li.menu-item {
      margin-bottom: 0.7rem;
      line-height: 1;
      & > a {
        font-size: 2rem;
        font-weight: $font-weight-medium;
        text-decoration: none;
        color: $color-white;
        transition: $trans;

        &:hover {
          color: $color-white;
          transition: $trans;
        }
        @media screen and (max-width: 767px) {
          font-size: 1.875rem;
        }
        @media screen and (max-width: 480px) {
          font-size: 1.75rem;
        }
      }
      ul.sub-menu {
        margin-top: $font-size;
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0px;
          a {
            background-color: $color-secondary;
            color: $color-background !important;
            font-size: 1.5rem;
            padding: 4px 8px;
            border-radius: 50px;
            display: inline-block;
            font-weight: $font-weight-medium;
            &:hover {
              background-color: $color-secondary-dark !important;
            }
            @media screen and (max-width: 767px) {
              font-size: 1.25rem;
            }
            @media screen and (max-width: 480px) {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
  &__contacto {
    .contactos__title {
      display: none;
    }
  }
  &__rs {
    display: flex;
    justify-content: center;
    margin-bottom: $space-1;
    .widget-footer__title {
      display: none;
    }
    .social-icon {
      // font-size: 32px !important;
      // color: $color-primary !important;
      @media screen and (max-width: 767px) {
        // font-size: 28px !important;
      }
      &:hover {
        color: $color-primary-dark !important;
      }
    }
  }
  &__logo {
    display: flex;
    width: 180px;
    margin: 2rem auto 0 auto;
    @media screen and (max-width: 480px) {
      width: 160px;
    }
  }
}

.nav-overflow {
  padding: $font-size-smaller 0;
  .container {
    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

.nav-servicios {
  &__items {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    gap: 10px;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      overflow-x: scroll;
      flex-wrap: nowrap;
    }
    .menu-item {
      white-space: nowrap;
      a {
        display: inline-flex;
        background-color: $color-grey-3;
        color: $color-white;
        padding: 4px 12px;
        border-radius: 50px;
        font-size: $font-size-small;
        @media screen and (max-width: 767px) {
          padding: 2px 9px;
        }
      }
      &:first-child {
        @media screen and (max-width: 991px) {
          margin-left: $font-size;
        }
      }
      &:last-child {
        @media screen and (max-width: 991px) {
          margin-right: $font-size;
        }
      }
    }
    .current-menu-item {
      a {
        background-color: $color-secondary;
      }
    }
  }
}
