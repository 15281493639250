.cta {
  width: 100%;
  position: relative;
  padding: $space-5 0;
  z-index: 0;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      z-index: 3;
      opacity: 0.2;
      background: $color-dark;
    }
    img {
      position: relative;
      object-fit: cover;
      object-position: bottom center;
      width: 100%;
      height: 100%;
    }
  }

  &__container {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__content {
    color: $color-white;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__pretitulo {
    font-size: $font-size;
    margin-bottom: $font-size;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 575px) {
      margin-bottom: $space-05;
    }
  }

  &__titulo {
    // font-size: $header-1;
    font-family: $typo-alternate;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    margin-bottom: $space-1;
    font-size: clamp($header-2, 10.5vw, 20rem);
    font-size: clamp(3rem, 5vw, 12vw);
    @media screen and (max-width: 767px) {
      line-height: 1.4;
    }
  }

  &__descripcion {
    font-size: $font-size-big;
    text-align: center;
    max-width: 50%;
    @media screen and (max-width: 991px) {
      max-width: initial;
    }
  }

  &__actions {
    margin-top: $space-1;
    display: flex;
    flex-wrap: wrap;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      text-align: center;
      justify-content: center;
      flex-direction: column;
    }
    .boton {
      @media screen and (max-width: 767px) {
        flex: 1;
      }
    }
  }
}
