.empresa {
  padding: $space-4 0;
  background-color: $color-background-light;

  &__content {
  }
}

.galeria {
  margin-top: 4px;
  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 4px;
    margin-bottom: 4px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  div.galeria__content-item &__content-item {
    iframe {
      aspect-ratio: 16/11;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content-img {
    aspect-ratio: 16/11;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.g {
  padding: $space-4 0;
  background-color: $color-grey-1;
  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16/ 9;
    border-radius: $border-radius;
    box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.5);
    @media screen and (max-width: 767px) {
      aspect-ratio: 16 / 8;
    }
  }
}
