.contacto {
  padding: $space-5 0;
  background-color: $color-background;
  @media screen and (max-width: 991px) {
    padding: $space-3 0;
  }
  &__content {
    color: $color-text-body;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__col1 {
    @media screen and (max-width: 991px) {
      margin-bottom: $space-2;
    }
  }

  &__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    color: $color-text-body;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__descripcion {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-3;
    margin-left: 0.725rem;
    font-size: $font-size;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-2;
    }
    a {
      text-decoration: none;
    }
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    .bi {
      margin-right: 0.5rem;
    }
    &:nth-last-child(1) {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  &__social {
    .zoom-social-icons-list {
      //margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
    }
    .social-icon {
      // font-size: 28px !important;
      // color: $color-primary !important;
      @media screen and (max-width: 767px) {
        font-size: 26px !important;
      }
      &:hover {
        color: $color-primary-dark !important;
      }
    }
  }
}

.ubicacion {
  background-color: $color-background-footer;
  iframe {
    width: 100%;
    height: 550px;
    @media screen and (max-width: 767px) {
      height: 500px;
    }
    @media screen and (max-width: 575px) {
      height: 400px;
    }
    @media screen and (max-width: 480px) {
      height: 320px;
    }
  }
}
