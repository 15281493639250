.slick-list {
  padding-bottom: $space-1 !important;
  @media screen and (max-width: 991px) {
    padding-bottom: $font-size !important;
  }
}

.slick-dots {
  margin-top: $space-1 !important;
  @media screen and (max-width: 991px) {
    margin-top: $font-size !important;
  }
}

.slick-dots li {
  @media screen and (max-width: 575px) {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

.slick-dots li.slick-active button::before {
  color: $color-grey-2 !important;
}

.slick-dots li button::before {
  font-size: 0.5rem !important;
  color: $color-grey-4 !important;
  @media screen and (max-width: 575px) {
    font-size: 0.5rem !important;
  }
  &:hover {
    transform: scale(1.2) !important;
  }
}
