$colors-badge: (
  "primary" $color-primary $color-white,
  "secondary" $color-secondary $color-white,
  "light" $color-white $color-dark,
  "dark" $color-dark $color-white,
  "accent" $color-accent $color-white
);

.badge {
  padding: 3px 6px;
  border-radius: 4px;
  @each $name, $color-badge, $color-badge-text in $colors-badge {
    &--#{$name} {
      background-color: $color-badge;
      color: $color-badge-text;
    }
  }
  @each $name, $color-badge, $color-badge-text in $colors-badge {
    &--outline-#{$name} {
      background-color: $color-white;
      border: 1px solid $color-badge;
      color: $color-badge-text;
    }
  }
  &--rounded {
    border-radius: 50px;
  }
  &--bold {
    font-weight: $font-weight-bold;
  }
  &--sm {
    padding: 2px 7px;
    font-size: $font-size-small;
  }
  &--ico {
    display: flex;
    align-items: center;
    img,
    svg {
      margin-right: 5px;
    }
  }
}
