.post-body {
  p,
  ul,
  ol {
    font-size: $font-size;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    li {
      padding-left: $space-1;
      position: relative;
      margin-bottom: 3px;
      &::before {
        left: 0;
        position: absolute;
        color: red;
        content: "\F26E";
        font-family: "bootstrap-icons";
      }
    }
  }
}

.page,
.post {
  &__entry {
    color: $color-text-body;
    line-height: 1.7;
    p,
    ul,
    ol,
    blockquote {
      a {
        color: $color-secondary;
        font-weight: $font-weight-medium;
        &:hover {
          color: $color-secondary-dark;
        }
        // text-decoration: underline;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }
    h2 {
      margin-top: $space-2;
    }
    p,
    ol,
    ul {
      font-size: $font-size;
      @media print {
        font-size: 10pt;
      }
    }
    p,
    ol,
    ul,
    blockquote {
      margin-bottom: 1.5rem;
    }

    ul {
      li {
        margin-bottom: 10px;
      }
    }

    h2 {
      font-size: 2.2rem;
      color: $color-primary !important;
      font-weight: $font-weight-bold;
      @media screen and (max-width: 575px) {
        font-size: 2rem;
        line-height: 1.3;
      }
      & strong {
        font-weight: 600;
      }
    }
    h3 {
      font-size: 1.5rem;
      color: $color-primary;

      font-weight: $font-weight-bold;
      @media screen and (max-width: 575px) {
        font-size: 1.4rem;
        line-height: 1.3;
      }
    }
    strong {
      font-weight: 600;
    }
    .wp-block-pullquote {
      padding: 2rem 0 !important;
      text-align: left !important;
      margin-bottom: 0 !important;
    }
    .wp-block-image {
      margin: 2rem 0;

      @media print {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: $border-radius !important;
        box-shadow: $box-shadow;
      }
      figcaption {
        text-align: center;
        font-size: $font-size-smaller;
      }
    }
    .wp-block-gallery {
      margin: 1.8rem 0;
      .blocks-gallery-item {
        margin-bottom: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      figcaption {
        text-align: center;
      }
    }
    .block-wikiloc {
      iframe {
        width: 100%;
        margin-top: 2rem !important;
        margin-bottom: 2rem;
        box-shadow: $box-shadow;
        border-radius: 0.5rem;
      }
      div {
        display: none;
      }
    }
  }
}
