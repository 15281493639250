.g-servicios {
  padding: $space-4 0 $space-5 0;
  background-color: $color-background-light;
  @media screen and (max-width: 767px) {
    padding-bottom: $space-4;
  }
}

.r-servicios {
  padding: $space-4 0;

  background-color: $color-background;
  color: $color-text-body;
  &__grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
    }
  }

  &__grid--3-1 {
    .r-servicios__item {
      @media screen and (min-width: 992px) {
        &:nth-last-child(1) {
          grid-column: 3 / span 2;
        }
      }
    }
  }

  &__grid--3-2 {
    .r-servicios__item {
      @media screen and (min-width: 992px) {
        &:nth-last-child(2) {
          grid-column: 2 / span 2;
        }
        &:nth-last-child(1) {
          grid-column: 4 / span 2;
        }
      }
      @media screen and (max-width: 991px) {
        &:nth-last-child(1) {
          grid-column: 2 / span 2;
        }
      }

      @media screen and (max-width: 767px) {
        &:nth-last-child(1) {
          grid-column: 1 / span 2;
        }
      }
    }
  }

  &__content {
    text-align: center;
  }

  &__item {
    grid-column: span 2;
    @media screen and (max-width: 991px) {
      &:nth-last-child(1) {
        grid-column: 2 / span 2;
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
      &:nth-last-child(1) {
        grid-column: 1 / span 2;
      }
    }
  }

  &__picture {
    margin-bottom: $space-1;
    padding: $font-size;
    // border: 2px dashed $color-border;
    // border-radius: $space-1;
    @media screen and (max-width: 767px) {
      border-radius: $space-01;
    }

    img {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: auto;
      object-fit: cover;
      //@include img-round;
    }
  }

  &__title {
    text-align: center;
    font-size: $header-2;
    font-family: $typo-secondary;
    letter-spacing: -1px;
    margin-bottom: 1rem;
    color: $color-primary;
    cursor: pointer;
    &:hover {
      color: $color-secondary;
    }
    @media screen and (max-width: 1280px) {
      font-size: $header-2-res;
    }
  }
}

.servicio {
  padding: $space-4 0;
  background-color: $color-background-light;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__left {
  }

  &__picture {
    margin-bottom: $space-2;
    img {
      background: $color-white;
      box-shadow: $box-shadow-small;
      border-radius: $border-radius;
      width: 100%;
      height: auto;
    }
  }

  &__right {
  }

  &__seo {
    font-size: $header-5;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    color: $color-primary;
    position: relative;
    @include after-line;
  }
  &__excerpt {
    margin-bottom: $space-2;
    font-size: $header-3;
    font-weight: $font-weight-bold;
    @include after-line;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
  }
}

.contactos {
  &__title {
    font-size: $header-5;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-6;
      margin-bottom: $font-size-small;
    }
  }

  &__actions {
    display: flex;
    gap: $font-size;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      gap: $font-size-smaller;
    }
    .boton {
      flex: 1;
    }
  }
}
