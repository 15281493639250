.header {
  // padding: $font-size 0;
  // background-color: $color-white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  transition: $trans;
  display: flex;
  align-items: center;
  @media screen and (max-width: 575px) {
  }

  &--sticky {
    background-color: $color-primary-dark;
    box-shadow: $box-shadow;
    overflow: hidden;

    .header__logo {
      padding: $font-size-smaller $font-size !important;
      border-radius: 0;
      position: relative;
      transition: $trans;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 2000px;
        left: -2000px;
        background-color: $color-white;
        transition: $trans;
      }
    }
  }

  &__logo {
    background-color: $color-white;
    padding: $space-1 $font-size;
    border-radius: 0 0 8px 8px;
    transition: $trans;
    @media screen and (max-width: 767px) {
      padding: $font-size-smaller;
    }
    .logo {
      width: 220px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 767px) {
        width: 170px;
        transition: $trans;
      }
    }
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__burger {
    margin-left: $space-01;
    i {
      color: $color-white;
      font-size: 2.5rem;
      transition: $trans;
      // &:hover {
      //   color: $color-primary-dark;
      //   transition: $trans;
      // }
    }
    line-height: 1;
    @media screen and (min-width: 700px) {
      display: none;
      transition: $trans;
    }
  }

  &__nav {
    margin-left: auto;
    margin-right: $space-01;
    @media screen and (max-width: 699px) {
      display: none;
      transition: $trans;
    }
  }

  &__social {
    .zoom-social-icons-list {
      margin-left: 0 !important;
      display: flex;
      justify-content: center;
    }
    .social-icon {
      font-size: 24px !important;
      color: $color-white !important;
      @media screen and (max-width: 699px) {
        font-size: 28px !important;
      }
      &:hover {
        color: $color-white !important;
      }
    }
    @media screen and (max-width: 699px) {
      margin-left: auto;
    }
  }
}
