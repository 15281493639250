@mixin button {
  padding: 0.75rem 1.475rem; // cambiar tamaño
  font-size: 1rem;
  border-radius: $space-2; // cambiar radius
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 0.5rem 1.25em;
    font-size: $font-size-small;
  }
}

@mixin after-line {
  padding-bottom: $font-size;
  position: relative;
  &::after {
    content: "";
    width: 100px;
    background-color: $color-primary;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
