.destacado {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding-top: $space-4;
    padding-bottom: $space-4;
  }

  &__content {
    display: flex;
    gap: $space-1 / 2;

    @media screen and (max-width: 991px) {
      flex-direction: column;
      flex-wrap: wrap;
      gap: $space-1;
    }
  }

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $space-1;
    @media screen and (max-width: 991px) {
      display: grid;
      width: 100%;
      gap: $space-1;
      grid-template-columns: 80px auto;
      padding: 0;
      align-items: flex-start;
    }
  }

  &__icono {
    margin-bottom: 1.25rem;
    background-color: $color-primary-dark;
    border-radius: 100px;
    aspect-ratio: 1 / 1;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 991px) {
      margin-bottom: 0;
      width: 80px;
    }
    img,
    svg {
      max-width: 40px;
      max-height: 40px;
      @media screen and (max-width: 767px) {
        max-width: 25px;
        max-height: 25px;
      }
      path {
        fill: $color-white;
        // stroke: $color-white !important;
      }
      polygon,
      circle {
        // stroke: $color-white !important;
      }
      @media screen and (max-width: 767px) {
        height: 80px;
      }
    }
  }

  &__info {
    text-align: center;
    @media screen and (max-width: 991px) {
      text-align: left;
    }
  }

  &__titulo {
    font-family: $typo-alternate;
    color: $color-secondary;
    font-size: $header-3;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size-smaller;
    @media screen and (max-width: 991px) {
      margin-bottom: $font-size-smaller / 2;
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-small;
  }
}

.h-servicios {
  padding: $space-4 0;
  background-color: $color-background;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__slider {
    margin-top: -$space-2 !important;
    @media screen and (max-width: 991px) {
      margin-top: 0 !important;
    }
  }

  &__item {
    margin: 0 $font-size;
    padding-top: $font-size-big;
    @media screen and (max-width: 575px) {
      margin: 0 10px;
    }
  }

  &__img {
    display: block;
    margin-bottom: $font-size;
    padding: $font-size;
    background: $color-white;
    box-shadow: $box-shadow-small;
    border-radius: $border-radius;
    img {
      aspect-ratio: 16/9;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__overlay {
  }

  &__titulo {
    color: $color-primary;
    font-size: $header-3;
    font-weight: $font-weight-bold;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: $header-5;
    }
    &:hover {
      color: $color-secondary;
    }
  }

  &__actions {
    margin-top: $space-3;
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
}

.marcas {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding-top: $space-3;
    padding-bottom: $space-3;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: $space-03;
    // @media screen and (max-width: 480px) {
    //   gap: $space-1 $font-size;
    // }
    @media screen and (max-width: 767px) {
      gap: $font-size-small;
    }
  }

  &__item {
  }

  &__img {
    img {
      width: 160px;
      height: auto;
      max-width: 130px;
      max-height: 70px;
      @media screen and (max-width: 767px) {
        width: 160px;
        height: auto;
        max-width: 75px;
        max-height: 55px;
      }
    }
  }
}

.certificaciones {
  padding: $space-4 0 $space-5 0;
  background-color: $color-background;
  &__content {
    display: flex;
    justify-content: center;
    gap: $space-4;
    @media screen and (max-width: 767px) {
      gap: $space-1;
      flex-direction: column;
    }
  }

  &__item {
    overflow: hidden;
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: $font-size;
    background: $color-grey-6;
    border: 1px solid $color-grey-5;
    border-radius: $border-radius;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  &__img {
    background: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $font-size;
    img {
      max-width: 70%;
      max-height: auto;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: $font-size;
    @media screen and (max-width: 767px) {
      align-items: center;
      text-align: center;
    }
  }

  &__title {
    font-family: $typo-primary;
    font-size: $header-3;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    color: $color-primary;
    margin-bottom: 1rem;
  }

  &__description {
    color: $color-text-body;
    line-height: 1.5;
  }
}

.fibra {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-3;
  margin-bottom: $space-5;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: $space-2;
  }
  &__picture {
    flex: 1;
    display: flex;
    position: relative;
  }

  &__cover {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    border-radius: $border-radius;
    position: relative;
    margin-right: $space-1;
    box-shadow: $box-shadow-small;
  }

  &__cover-logo {
    position: absolute;
    bottom: -2rem;
    right: 0;
    width: 40%;
    height: auto;
    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius;
      box-shadow: $box-shadow-small;
    }
  }

  &__right {
    flex: 1;
  }

  &__titulo {
    font-size: $header-5;
    font-weight: bold;
    color: $color-secondary;
    text-transform: uppercase;
  }

  &__subtitulo {
    font-size: $header-2;
    font-weight: bold;
    font-family: $typo-alternate;
    color: $color-primary;
  }

  &__descripcion {
  }
}
