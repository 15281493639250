.page-title {
  padding: 12rem 0 $space-2 0;

  // background-color: #ff5100;
  // background-color: $color-primary-dark;
  // background: url("https://www.almisat.com/wp-content/uploads/2022/11/cta-bg-scaled-1.jpg")
  //   no-repeat center center;

  background-image: linear-gradient(135deg, #ff5100 0%, #f59366 100%);
  background-size: cover;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding: 6rem 0 $space-1 0;
  }

  .container {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 3;
    text-align: left;
  }

  &__h {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $display-3;
    font-family: $typo-alternate;
    margin-bottom: 0;
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-smaller;
    color: $color-white;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size-smaller / 2;
    }
    a {
      color: $color-white;

      text-decoration: underline;
      font-weight: $font-weight-bold;
      &:hover {
        color: $color-secondary;
      }
    }
  }
}
