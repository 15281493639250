.footer {
  padding: $space-3 0 $space-3;
  background-color: $color-background-footer;
  color: $color-text-footer;
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $space-2;
    @media screen and (max-width: 991px) {
      gap: $space-2;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
  }

  &__item--logo {
    @media screen and (max-width: 767px) {
      display: none;
    }
    img {
      width: 100%;
      max-width: 180px;
      height: auto;
      @media screen and (max-width: 480px) {
        width: 180px;
      }
    }
  }

  &__item--servicios {
  }

  &__item--contacto {
  }

  &__item--social {
    display: flex;
    justify-content: flex-start;

    .zoom-social-icons-list {
      margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      &__item {
        background-color: $color-primary;
      }
    }
    // .social-icon {
    //   font-size: 24px !important;
    //   color: $color-text-footer !important;
    //   &:hover {
    //     color: $color-grey-1 !important;
    //   }
    // }
  }
}

// .nav-legal {
//   &__items {
//     list-style: none;
//     padding-left: 0;
//     margin-bottom: 0;
//     display: flex;
//     gap: $space-1;
//     @media screen and (max-width: 480px) {
//       flex-direction: column;
//       gap: $space-05;
//       align-items: center;
//     }
//     & > li.menu-item {
//       a {
//         color: $color-text-footer;
//         font-weight: $font-weight-medium;
//         font-size: $font-size-small;
//         text-decoration: none;
//       }
//     }
//   }
// }

.widget-footer {
  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    font-size: $font-size;
    text-transform: uppercase;
    position: relative;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
      text-transform: initial;
    }
    &::before {
      position: absolute;
      top: -$font-size;
      left: 0;
      content: "";
      display: block;
      width: 50px;
      height: 1px;
      background-color: $color-secondary;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .textwidget {
    p {
      font-size: $font-size-small;
      color: $color-text-footer;
    }
  }

  ul.menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    .menu-item {
      a {
        color: $color-primary;
        font-size: $font-size-small;
      }
    }
  }
}

.copyright {
  background-color: $color-background-footer;
  font-size: $font-size-small;
  &__container {
    padding-top: $space-1;
    padding-bottom: $space-3;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__copy {
    color: $color-text-footer;
    font-size: $font-size-small;
    @media screen and (max-width: 480px) {
      text-align: center;
    }
  }
}

.next-gen {
  padding-bottom: $space-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: $font-size / 2;
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 200px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
