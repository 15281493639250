.hero {
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__video {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $color-black;
    opacity: 0;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    padding-left: $space-1;
    padding-right: $space-1;
  }

  &__content {
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $color-white;
    transition: $trans;
  }

  &__pretitulo {
    text-transform: uppercase;
    font-size: $font-size;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size / 2;
  }

  &__titulo {
    font-family: $typo-alternate;
    font-weight: 700;
    // font-size: clamp($header-3, 10.5vw, $display-2);
    font-size: clamp(2rem, 5vw, 12vw);
    line-height: 1.2;
    margin-bottom: $space-1;
    transition: $trans;
  }

  &__subtitulo {
    font-family: $typo-secondary;
    //color: $color-;
    font-size: $header-4;
    transition: $trans;

    @media screen and (max-width: 575px) {
      font-size: 1.15rem;
      transition: $trans;
    }
  }

  &__actions {
    margin-top: $space-1;
    display: flex;
    align-items: center;
    gap: $font-size;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      & > * {
        flex: 1;
        // width: 100%;
      }
    }
  }
}

.br-mvl {
  @media screen and (min-width: 768px) {
    display: none;
  }
}
.br-mvl-991 {
  @media screen and (min-width: 992px) {
    display: none;
  }
}
